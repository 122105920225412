import gql from 'graphql-tag'
import { print } from 'graphql'
import { Headers, GET_ENDPOINT_URL } from '../constants/constants'
import axios from 'axios'

const getFeatureFlags = async (id, env) => {
  const body = {
    query: print(gql`
          query {
              getFeatureFlags(id: "${id}") {
                id
                features {
                    name
                    roles
                }
              }
          }`)
  }
  const options = {
    headers: Headers(env)
  }

  const result = await axios.post(GET_ENDPOINT_URL(env), body, options)
  return result?.data?.data?.getFeatureFlags
}

export default getFeatureFlags
