import { canView } from '../logic/logic'
import { useFFContext } from './Context'

const FFGate = ({ children, featureName }) => {
  const { state } = useFFContext()
  const { features, userRoles } = state

  // Get this out of the way early, featureName is required for this comp
  if (!featureName) {
    console.error('featureName (String) is required in <FFGate />')
    return null
  }

  return canView(features, featureName, userRoles) ? children : null
}

FFGate.defaultProps = {
  children: null
}

export default FFGate
