export const GET_ENV = (env) => {
  return env === 'prod' ? 'prod' : 'dev'
}

export const GET_ENDPOINT_URL = (env) => {
  switch (env) {
    case 'prod':
      return 'https://alerts.api.adyptation.com/graphql'
    case 'dev':
    default:
      return 'https://qa-alerts.api.adyptation.com/graphql'
    // default:
    //   return 'http://127.0.0.1:3000/graphql/'
  }
}

export const REQUESTER_EMAIL = 'steve@adyptation.com'

export const Headers = (env) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  env: GET_ENV(env),
  requester: REQUESTER_EMAIL
})
