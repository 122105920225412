export const initialState = {
  product: 'web',
  features: [],
  userRoles: [],
  loading: false
}

export const FFReducer = (current, action) => {
  const { type, value } = action
  let newState

  switch (type) {
    case 'SET_LOADING':
      newState = {
        ...current,
        loading: value
      }
      break
    case 'SET_FEATURES':
      newState = {
        ...current,
        product: value.id,
        features: value.features,
        loading: false
      }
      break
    case 'SET_USER_ROLES':
      newState = {
        ...current,
        userRoles: value
      }
      break
    default:
      newState = current
  }

  return newState
}
