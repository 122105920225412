import React, { useReducer, useEffect } from 'react'
import { getFeatureFlags } from '../api'
import { initialState, FFReducer } from './Reducer'
import { FFContext } from '../context'

const FFProvider = ({ children, userRoles, product, env }) => {
  const [state, dispatch] = useReducer(FFReducer, initialState)

  // onMount API call to get features
  useEffect(() => {
    methods.init(product, env, dispatch)
  }, [])

  // Sets userRole Array in state
  useEffect(() => {
    methods.roleUpdate(userRoles, dispatch)
  }, [userRoles])

  return (
    <FFContext.Provider value={{ state, dispatch }}>
      {children}
    </FFContext.Provider>
  )
}

FFProvider.defaultProps = {
  env: 'dev',
  product: 'web',
  userRoles: []
}

export default FFProvider

export const methods = {
  init: async (product, env, dispatch) => {
    try {
      dispatch({ type: 'SET_LOADING', value: true })
      const res = await getFeatureFlags(product, env)
      dispatch({ type: 'SET_FEATURES', value: res })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'SET_LOADING', value: false })
    }
  },
  roleUpdate: (userRoles, dispatch) => {
    // Validate userRoles is an array
    if (!Array.isArray(userRoles)) {
      console.error('userRoles must be in the shape of Array in <FFProvider />')
      return
    }

    // Updates user roles
    dispatch({ type: 'SET_USER_ROLES', value: userRoles })
  }
}
