const canView = (features, featureName, userRoles) => {
  // console.log(features, featureName, userRoles)

  // First scan for the featureName within the features array
  const thisFeature = features.find((feature) => feature.name === featureName)

  // If none found, deny viewing access
  if (!thisFeature) return false

  // Superusers get access to everything by default
  if (userRoles.includes('superuser')) return true

  // Check each role allowed in this specific feature
  // against all the roles the user has
  // returns true/false based on if this feature has a role found in userRoles
  const allowed = thisFeature.roles.some((role) => userRoles.includes(role))
  return allowed
}

export { canView }
